@import-normalize;

@import "./Fonts";
@import "./Variables";
@import "./Buttons";

.App {
  min-height: 100vh;
}
    /* the slides */
    .slick-slide {
      margin: 0px;
    }
    /* the parent */
    .slick-list {
      margin: 0px;
    }
body {
  font-family: $base-font;
  font-size: 16px;
  line-height: 22px;
  padding: 0;
  margin: 0;
  color: $black;
  max-width: 100%;
}

h2 {
  font-family: $base-font;
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: $ocean;
  margin: 0 0 20px;
}
h3 {
  font-family: $base-font;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: $ocean;
  margin: 0 0 5px;
}

p {
  font-family: $base-font;
  font-size: 16px;
  line-height: 19px;
  color: $black;
  margin: 0 0 14px;
  strong {
    font-weight: 700;
    color: $ocean;
  }
  &.reference {
    font-size: 12px;
    line-height: 16px;
  }
  &.subtitle {
    color: $ocean;
    font-weight: 500;
    margin: 0;
  }
  &.bold {
    font-weight: 700;
  }
}

a {
  color: $ocean;
  cursor: pointer;
}
.handCursor, .link{
  cursor: pointer;
}
sup {
  font-size: 10px;
  line-height: 10px;
}

.sidebar {
  width: 28%;
  padding: 0;
  height: calc(100vh - 130px);
}
.flex {
  display: flex;
}
