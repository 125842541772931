@import "./Variables";
.references {
    position: absolute;
    right: 0;
    top: 10px;
    display: flex;
    background: $white;
    z-index: 3;
    .icon_references {
        margin-top: 15px;
    }
    .references-copy {
        box-shadow: -5px -3px 10px -10px $stone;
        height: calc(100vh - 176px);
        padding: 30px;
        button {
            @include closeButton(30px, 3px, $ocean, $black);
            right: 10px;
            top: 10px;
            position: absolute;
        }
        h2 {
            border-bottom: 2px solid $ocean;
            padding-bottom: 10px;
        }
        ol {
            padding-left: 20px;
            li {
                padding-left: 15px;
                margin-bottom: 10px;
                font-size: 20px;
                line-height: 26px;
            }
        }
    }
    
}