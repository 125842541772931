@import './Base.scss';
header .utility-nav button {
    color: #fff;
    text-decoration: none;
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
    background: transparent;
    padding: 0px;
    border-width: 0px;
    border-style: none;
    border-color: none;
    border-image: none;
}
header {
    padding: 0 80px 12px 20px;
    display: flex;
    box-shadow: 0 2px 15px -8px $stone;
    .logo {
        margin: 20px 0 0;
    }
    .title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        color: $ocean;
        margin: 15px 0 0 40px;
        align-self: center;
    }
    .utility-nav {
        padding: 20px 5px;
        background: $stone;
        margin-left: auto;
        height: max-content;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            li {
                padding: 8px 20px;
                a {
                    color: $white;
                    text-decoration: none;
                    font-size: 15px;
                    line-height: 20px;
                    font-weight: 700;
                    
                }
                &:first-child {
                    border-right: 2px solid $white;
                }
            }
        }
    }
}