@import "./Variables";

.main-wrapper {
    display: flex;
    .main {
        width: 72%;
        padding: 25px 50px 20px 25px;
        .model-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.5);
            border-top-right-radius: 25px;
            z-index: 1;
            display: none;
            &.overlay-current {
                display: block;
            }
            > div {
                width: 100%;
                text-align: center;
                opacity: 0.75;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                p {
                    color: $white;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 700;
                    margin: 10px 0 0;
                }
            }
        }
        .model-content {
            display: none;
            &.model-current {
                display: block;
            }
        }
        .slide-content {
            display: none;
            &.slide-current {
                display: block;
            }
            > h2 {
                position: absolute;
                top: 25px;
                left: 25px;
                color: $light-stone;
                font-size: 29px;
                line-height: 38px;
                font-weight: 700;
            }
            .model-left {
                position: absolute;
                right: 40px;
                top: 50px;
                display: flex;
                align-items: center;
                .red-line {
                    width: 25px;
                    height: 10px;
                    border-radius: 20px;
                    background: $red;
                    margin-right: 10px;
                }
                img {
                    margin-right: 10px;
                }
                p {
                    font-size: 18px;
                    line-height: 22px;
                    color: $white;
                    margin: 0;
                }
            }
            .model-bottom {
                background: $black;
                position: absolute;
                bottom: 0;
                width: 100%;
                p {
                    font-size: 18px;
                    line-height: 35px;
                    font-weight: 700;
                    color: $light-stone;
                    text-align: center;
                    margin: 0;
                }
            }
        }
    }
}