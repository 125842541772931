@import "./Variables";

.ReactModal__Content {
    button {
        @include closeButton(30px, 3px, $ocean, $black);
        right: 10px;
        top: 5px;
        position: absolute;
    }
}

.ReactModal__Overlay {
    z-index: 2;
}

.Modal {
    position: absolute;
    background-color: $white;
    width: max-content;
    max-width: 65%;
    max-height: 75vh;
    overflow: scroll;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    padding: 70px;
    img {
        max-width: 100%;
    }
    &.modal_enliven_data {
        > div {
            img {
                margin-bottom: 20px;
            }
            p {
                font-size: 12px;
                line-height: 16px;
                margin: 0;
            }
        }
    }
    &.modal_secondary_endpoint {
        > div {
            max-width: 720px;
            margin: 0 auto;
            img {
                margin-bottom: 20px;
            }
            p {
                font-size: 12px;
                line-height: 16px;
                margin: 0;
            }
        }
    }
}

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:rgba(88, 100, 117, 0.3);
    background-color: #58647583;
  }

  .iframe-wrapper{
      width:1100px;
      height: 75vh;
  }

.modal_pooled_data{
    padding:70px 100px 70px 125px;
    .text-div1{
        h4{
            font-size:17px;
            line-height:19px;
            font-weight: 700;
            color:$deep-sky;
            margin-bottom:0;
        }
        ul{
            margin:0;
            li{
                font-size:11px;
                line-height:13px;
            }
        }
        ul:last-of-type{
            margin: 0 0 25px;
        }
        ul li::marker{
            color:$deep-sky;
        }
    }
    .flex{
        display:flex;
    }
    .two-cols{
        font-family: $base-font;
        .left-col{
            margin-right: 140px;
            .green-div{
                background-color:$green;
                padding: 15px 0 15px 50px;
                border-bottom-right-radius: 30px;
                margin-left: -50px;
                p{
                    vertical-align: middle;
                    color:$white;
                    font-size:17px;
                    line-height:19px;
                    font-weight: 700;
                    margin:0;
                }
            }
            .chart-and-footnote{
                margin-top:10px;
                p{
                    font-size:10px;
                    line-height:12px;
                    margin:0;
                    sup{
                        font-size:8px;
                        line-height:1px;
                    }
                }
            }
        }
        .right-col{
            .blue-div{
                background-color: $deep-sky;
                border-top-left-radius: 30px;
                padding:15px 20px;
                p{
                    color:$white;
                    font-size:17px;
                    line-height:19px;
                    font-weight: 700;
                }
                ul{
                    padding: 0 0 0 15px;
                    margin:0;
                    li{
                        color:$white;
                        font-size:11px;
                        line-height:13px;
                        margin-bottom:5px;
                        font-weight: 400;
                    }
                }
                ul li::marker{
                    color:$white;
                }
            }
        }
    }
}