@import "./Variables";
.study {
    padding-right: 60px;
    position: relative;
    height: 100%;
    .study-header {
        color: $white;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        display: flex;
        margin: 0 0 20px;
        border-bottom: 5px solid $ocean;
        text-align: center;
        > div {
            padding: 20px;
            border-top-right-radius: 15px;
            margin-top:25px;
            cursor: pointer;
            &:first-child {
                background: $ocean;
                width: calc(50% - 30px);
                margin-right: 30px;
            }
            &:last-child {
                background: $green;
                width: 50%;
            }
        }
        &.study-current {
            border-bottom-color: $green;
        }
    }
    .sidebar-ftr {
            display: flex;
            text-align: center;
            position: absolute;
            width: calc(100% - 60px);
            bottom: 0;
        > button {
            margin: 0;
            &:last-child {
                margin-left: auto;
            }
        }
    }
    .box-green {
        background: rgba(178, 196, 63, 0.25);
        padding: 10px 40px;
        border-top: 5px solid $ocean;
        border-bottom: 5px solid $ocean;
        margin: 0 0 10px;
        p {
            margin: 0;
            font-size: 18px;
            line-height: 21px;
            color: $ocean;
            font-weight: 700;
            text-align: center;
        }
    }
    .slick-slider {
        .slick-list {
            margin-bottom: 0;
            overflow-y: hidden;

            ul {
                padding-left: 20px;
                list-style: none;
                margin: 14px 0;
                li {
                    line-height: 20px;
                    margin: 0 0 5px;
                    &:before {
                        content: "\2022";
                        // color: $ocean;
                        color: #000;
                        display: inline-block;
                        width: 15px;
                        margin-left: -17px;
                        font-size: 20px;
                        font-weight: 700;
                        vertical-align: top;
                    }
                }
            }
        }
    }
    .slick-dots {
        width: 50%;
        left: 50%;
        bottom: -30px;
        transform: translateX(-50%);
        z-index: 1;
        li {
            width: 14px;
            height: 14px;
            margin: 0 5px;
            button {
                width: 12px;
                height: 12px;
                &:before {
                    content: '';
                    width: 12px;
                    height: 12px;
                    border: 2px solid $deep-sky;
                    border-radius: 25px;
                    opacity: 1;
                }
            }
            &.slick-active {
                button {
                    &:before {
                        background: $deep-sky;
                    }
                }
            }
        }
    }
    .inner-div {
        padding-right: 20px;
        box-sizing: border-box;
    }
    .slick-slider {
        height: calc(100vh - 300px);
        .slick-list {
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            padding-bottom: 0;
            box-sizing: border-box;
            &::-webkit-scrollbar {
                width: 7px;
                position: absolute;
                top: 0;
                left: 0;
            }
            &::-webkit-scrollbar-track {
                box-shadow: none;
                border-radius: 7px;
            }

            &::-webkit-scrollbar-thumb {
                background: rgba(0, 0, 0, 0.15);
                border-radius: 10px;
            }
        }
    }
}
