@import "./Variables";

.model {
    .range-slider {
        &.range-slider-rom {
            left: 160px;
            @media (max-width: 1550px) {
                left: 100px;
            }
            h3 {
                color: $green;
            }
            .slider-bar {
                background: rgb(178, 196, 63) !important;
            }
        }
    }
    .rangeROM0 {
        display: none;
        position: absolute;
        bottom: 190px;
        left: 160px;
        &.range-box-current {
            display: block;
        }
        p {
            font-size: 20px;
            line-height: 26px;
            color: $white;
            margin: 0;
            padding: 0 0 10px;
            border-bottom: 6px solid $green;
            &:after {
                content:'';
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                margin: 0 auto;
                width: 0;
                height: 0;
                border-top: solid 30px $green;
                border-left: solid 15px transparent;
                border-right: solid 15px transparent;
            }
        }
    }
    .rangeROM100 {
        display: none;
        position: absolute;
        bottom: 145px;
        right: 0px;
        &.range-box-current {
            display: block;
        }
        > div {
            > div {
                width: 300px;
                display: flex;
                align-items: center;
                font-size: 20px;
                line-height: 26px;
                position: relative;
                p {
                    font-size: 16px;
                    line-height: 26px;
                    margin: 0 auto;
                    color: $white;
                }
                &:first-child {
                    background: $light-stone;
                    min-height: 105px;
                    margin: 0 20px 45px;
                    border-top-right-radius: 25px;
                    border-bottom-left-radius: 25px;
                    &:after {
                        content:'';
                        position: absolute;
                        top: 100%;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        width: 0;
                        height: 0;
                        border-top: solid 30px $light-stone;
                        border-left: solid 15px transparent;
                        border-right: solid 15px transparent;
                    }
                }
                &:nth-child(2) {
                    background: $green;
                    color: $white;
                    min-height: 105px;
                    padding: 10px;
                    margin: 0 20px 35px;
                    border-top-right-radius: 25px;
                    border-bottom-left-radius: 25px;
                    &:after {
                        content:'';
                        position: absolute;
                        top: 100%;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        width: 0;
                        height: 0;
                        border-top: solid 30px $green;
                        border-left: solid 15px transparent;
                        border-right: solid 15px transparent;
                    }
                }
                &:last-child {
                    font-weight: 700;
                    color: $green;
                    justify-content: center;
                    margin: 0 auto;
                }
            }
        }
        .target-point{
            margin: 0 20px 45px 105px;
            width:auto;
            p {
                font-size: 20px;
                line-height: 26px;
                color: $white;
                margin: 0;
                padding: 0 0 10px;
                border-bottom: 6px solid $green;
                &:after {
                    content:'';
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 80px;
                    margin: 0 auto;
                    width: 0;
                    height: 0;
                    border-top: solid 30px $green;
                    border-left: solid 15px transparent;
                    border-right: solid 15px transparent;
                }
            }
        }
    }
}