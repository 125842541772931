.button {
    cursor:pointer;
    border:none;
    box-shadow: none;
    outline: none;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    background: $deep-sky;
    font-family: $base-font;
    color: $white;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 10px;
    min-width: 110px;
    &.button-data {
        width: 220px;
        height: 66px;
        background: $green;
        font-size: 18px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    &.button-rom-data {
        width: auto;
        margin-bottom: 10px;
        padding: 0 40px;
    }
}

.buttons-wrap {
    display: flex;
    margin: 0 0 15px;
    > div {
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
    }
}
 