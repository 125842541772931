@import "./Variables";

.isi-content {
    .box {
        border: 2px solid $black;
        padding: 20px;
        margin-bottom: 15px;
        h3 {
            color: $black;
        }
        ul {
            margin: 7px 0 0;
            padding-left: 20px;
            li {
                font-weight: 700;
                margin: 0 0 5px;
            }
        }
        ul li::marker {
            color:$black;
        }
    }
    h4 {
        margin: 0 0 5px;
    }
    ul {
        margin: 5px 0;
        padding-left: 15px;
    }
    ul li::marker {
        color:$ocean;
    }
}
.modal_isi {
    padding: 30px 75px 40px;
    .isi-content {
        max-height: 60vh;
    }
    h2 {
        font-size: 25px;
        line-height: 32px;
    }
}
.isi {
    position: absolute;
    bottom: 0;
    background: $gray;
    padding: 20px 15px 25px 25px;
    z-index: 2;
    .isi-content {
        max-height: 21vh;
    }
    button {
        border: none;
        background: none;
        position: absolute;
        right: 10px;
        top: 25px;
        outline: none;
        .arrow {
            border: solid $stone;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 5px;
            &.up {
                transform: rotate(-135deg);
            }
        }
    }
    h2 {
        font-size: 20px;
        line-height: 26px;
    }
    &.collapsed {
        z-index: 3;
        button {
            .arrow {
                &.up {
                    transform: rotate(45deg);
                }
            }
        }
        .isi-content {
            max-height: 100vh;
            height: calc(100vh - 205px);
        }
    }
}