//Font Family
$base-font: 'Roboto', sans-serif;;

// Colors
$white: #fff;
$black: #000;
$ocean: #00857D;
$green: #B2C43F;
$sky: #A5C8E4;
$stone: #586475;
$deep-ocean: #005A54;
$dark-leaf-green: #73943C;
$deep-sky: #0086B6;
$light-stone: #98A2B0;
$gray: #eaecef;
$red: #EA2F35;
$blue: #A5C8E4;
// Breakpoints
$desktop: 1366px;
$tablet: 1365x;
$mobile: 767px;

@mixin closeButton ($size, $thickness, $color, $colorHover) {
  
    & {
      display: inline-block;
      width: $size;
      height: $size;
      background: none;
      border: none;
    }
    
    &:before, &:after {
      content: '';
      display: block;
      width: 140%;
      height: $thickness;
      background: $color;
    }
    
    &:before {
      transform: rotate(45deg);
      transform-origin: 0 0;
      margin-top: 0;
      margin-left: 2px;
    }
    
    &:after {
      transform: rotate(-45deg);
      transform-origin: 100% 0;
      margin-left: -41%;
      margin-top: -$thickness;
    }
    
    &:hover {
      cursor: pointer;
      
      &:before, &:after {
        background: $colorHover;
      } 
    }
}

.mb0 {
  margin-bottom: 0 !important;
}
.mb5 {
  margin-bottom: 5px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mt10 {
  margin-top: 10px !important;
}