@import "./Variables";

.model {
    background: url("../images/bg_model.png") no-repeat;
    width: 100%;
    height: 80vh;
    border-top-right-radius: 25px;
    position: relative;
    
    canvas {
        width: 100%;
        height: 100%;
        margin: 0 auto;
    }
    
    .range-slider {
        width: 950px;
        max-width: 100%;
        position: absolute;
        bottom: 100px;
        left: 80px;
        display: none;
        @media (max-width: 1550px) {
            width: 750px;
        }
        &.range-current {
            display: flex;
        }
        h3 {
            color: $white;
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
            margin-right: 35px;
        }
        > div {
            width: 100%;
        }
        .slider-bar {
            height: 15px;
            width: 100%;
            background-color: $blue;
            border-radius: 25px;
            position: relative; 
            // padding-right: 15px; 
            padding-right: 0px; 
            padding-left: 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .slider-point {
            height: 8px;
            width: 8px;

            border-radius: 25px;  
            margin-left: 9px;
            &:nth-child(1){
                margin-left: 6px;
            }
        }
        .slider-pointer {
            height: 25px;
            width: 25px;
            background-color: $sky;
            border-radius: 25px;
            outline: none;
            margin-top: 8px;
        }
        .point-label {
            color:$white;
            font-size: 18px; 
            line-height: 24px;
            font-weight: 700;
            margin-top : 25px;  
            width: max-content; 
            // width: 200px;
            width: 213px;
            position: absolute;
            text-align: center; 
            margin-left: -96px; 
            p{
                color:$white;
            } 
        }
      

    }

    .range-box {
        width: 320px;
        max-width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 40px;
        background: $sky;
        padding: 30px 20px;
        border-left: 50px solid $deep-sky;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
        display: none;
        &.range-box-current {
            display: block;
        }
        &.rangeLa100,
        &.rangeMta100 {
            right: 30px;
            left: auto;
        }
        h2 {
            color: $white;
            font-size: 20px;
            line-height: 20px;
            font-weight: 700;
            margin: 0;
            position: absolute;
            left: -63px;
            top: 50%;
            transform: translateY(-50%) rotate(-90deg);
        }
        > div {
            display: flex;
            align-items: center;
            margin: 0 0 30px;
            h3 {
                font-size: 50px;
                line-height: 60px;
                font-weight: 700;
                color: $deep-sky;
                margin-right: 20px;
            }
            p {
                font-size: 20px;
                line-height: 26px;
                color: $deep-sky;
                margin: 0;
            }
        }
        .slider-bar {
            height: 6px;
            width: 80%;
            margin-left: 10%;
            background-color: $white;
            border-radius: 25px;
            position: relative;
           
        }
        .slider-point {
            height: 8px;
            width: 8px;
            // background-color: $white;
            background-color: transparent;
            border-radius: 25px;
            margin-top: 0px!important;
        }
        .slider-point:nth-of-type(1){
            background-color: $deep-sky !important;
        }
        .slider-pointer {
            height: 18px;
            width: 18px;
            background-color: $deep-sky;
            border-radius: 25px;
            outline: none;
            top: 0;
        }
        .point-label {
            color: $white;
            font-size: 14px;
            line-height: 19px;
            font-weight: 700;
            margin-top: 20px;
            width: 67px;
            position: absolute;
            text-align: center; 
            margin-left: -25px;
        }
        .active{
            color:$white;
        }
        .inactive{
            color:#D2E4F2;
        }
    }
    
    > p {
        &:last-of-type {
            position: absolute;
            color: $light-stone;
            font-size: 12px;
            line-height: 16px;
            font-style: italic;
            right: 40px;
            bottom: 35px;

        }
    }

    .percent-box {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 40px;
        z-index: 2 ;
        display: none;
        &.percent-box-current {
            display: block;
        }
        > div {
            display: flex;
            align-items: center;
            > div {
                &:first-child {
                    background-image: radial-gradient(circle at 110% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 80px, $gray 0, $gray 100%);
                    display: flex;
                    align-items: center;
                    min-width: 350px;
                    height: 80px;
                    padding: 20px;
                    border-bottom-left-radius: 25px;
                    position: relative;
                    margin-right: -30px;
                    p {
                        margin: 0;
                        font-size: 20px;
                        line-height: 26px;
                        color: $stone;
                    }
                }
                .btn-plus {
                    background: $gray;
                    color: $ocean;
                    border-radius: 25px;
                    font-size: 54px;
                    line-height: 0;
                    font-weight: 300;
                    font-family: $base-font;
                    padding: 0;
                    border: none;
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    top: 0;
                    z-index: -1;
                }
           
                .percent-box-copy {
                    background-image: radial-gradient(circle at -8% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 90px, #eaecef 0, #eaecef 100%);
                    display: flex;
                    align-items: center;
                    margin-left: -38px;
                    padding: 15px 50px 15px 80px;
                    position: relative;
                    &.wide {
                        margin-left: -22px;
                    }
                    p {
                        margin: 0;
                        &:not(.reference) {
                            font-size: 20px;
                            line-height: 26px;
                        }
                    }
                    .flex {
                        margin: 0 0 15px;
                        > div {
                            margin-right: 40px;
                            p {
                                margin: 0;
                            }
                            b, sup{
                                color: $stone;
                            }
                            ul {
                                margin: 0;
                                padding-left: 20px;
                                li {
                                    font-size: 20px;
                                    line-height: 26px;
                                    color: $stone;
                                    margin: 5px 0;
                                }
                            }
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                    button {
                        background: none;
                        border: none;
                        padding: 0;
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        width: 20px;
                        height: 20px;
                        &:before, &:after {
                            position: absolute;
                            content: '';
                            height: 20px;
                            width: 2px;
                            background-color: $ocean;
                            top: 0;
                        }
                        &:before {
                            transform: rotate(45deg);
                        }
                        &:after {
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }
    }
}

/****************************************************************
 *
 * CSS Percentage Circle
 * Author: Andre Firchow
 *
*****************************************************************/


// VARS
$circle-width: 0.08em;
$circle-width-hover: 0.04em;

// colors default
$primary-color: #00857D;
$secondary-color: #C7D2DA;
$bg-color: #fff;

$primary-color-green: #4db53c;
$primary-color-orange: #dd9d22; 
// CIRCLE
.rect-auto{
	clip: rect(auto, auto, auto, auto);
}
.pie {
	position: absolute;
	border: $circle-width solid $primary-color;
	width: 1 - (2 * $circle-width);
	height: 1 - (2 * $circle-width);
	clip: rect(0em, 0.5em, 1em, 0em);
	border-radius: 50%;
	transform: rotate(0deg);
}
.pie-fill {
	transform: rotate(180deg);
}

// main
.c100 {

	*, *:before, *:after {
		box-sizing: content-box;
	}

    font-size: 150px;
	position: relative;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	float: left;
	margin: 0;
    background-color: $secondary-color;
    z-index: 2;

	// center circle to its parent
	&.center{
		float: none;
		margin: 0 auto;
	}

	// centered value inside circle
	> span {
		position: absolute;
		width: 100%;
		z-index: 3;
		top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        line-height: 20px;
		color: $stone;
		display: block;
		text-align: center;
		white-space: nowrap;
		transition-property: all;
		transition-duration: 0.2s;
        transition-timing-function: ease-out;
        span {
            font-size: 46px;
            line-height: 50px;
            font-weight: 700;
            display: block;
        }
	}

	// background inside the circle
	&:after{
		position: absolute;
		top: $circle-width;
		left: $circle-width;
		display: block;
		content: " ";
		border-radius: 50%;
		background-color: $bg-color;
		width: 1 - (2 * $circle-width);
		height: 1 - (2 * $circle-width);
		transition-property: all;
		transition-duration: 0.2s;
        transition-timing-function: ease-in;
        z-index: 1;
	}

	// the slice (mask)
	.slice {
		position: absolute;
		width: 1em;
		height: 1em;
		clip: rect(0em, 1em, 1em, 0.5em);
	}

	// circle to show the status
	.bar {
		@extend .pie;
	}

    &.p13 {
        .bar {
            transform: rotate(46.8deg);
        }
    }
    &.p20 {
        .bar {
            transform: rotate(72deg);
        }
    }

}
// end circle